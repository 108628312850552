import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import politicsImage from 'assets/images/home/politics/politics.jpg';
import Animated from 'components/generals/AnimatedComponent';
import type { CollapseProps } from 'antd';
import { Collapse } from 'antd';
import { useLocation } from 'react-router-dom';

export default function Politics() {
    const { t } = useTranslation();
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const items: CollapseProps['items'] = [
        {
            key: '1',
            label: <h4>{t('politics.policy1.title')}</h4>,
            children: (
                <div className="nv__politics__item">
                    <p>{t('politics.policy1.paragraph1')}</p>
                    {/* <p>{t('politics.policy1.paragraph2')}</p> */}
                </div>
            ),
        },
        {
            key: '2',
            label: <h4>{t('politics.policy2.title')}</h4>,
            children: (
                <div className="nv__politics__item">
                    <p>{t('politics.policy2.paragraph1')}</p>
                    {/* <p>{t('politics.policy3.paragraph2')}</p>
                    <p>{t('politics.policy3.paragraph3')}</p>
                    <p>{t('politics.policy3.paragraph4')}</p> */}
                </div>
            ),
        },
        {
            key: '3',
            label: <h4>{t('politics.policy3.title')}</h4>,
            children: (
                <div className="nv__politics__item">
                    <p>{t('politics.policy3.description')}</p>

                    {/* <ul>
                        <li>{t('politics.policy2.paragraph1')}</li>
                        <li>{t('politics.policy2.paragraph2')}</li>
                        <li>{t('politics.policy2.paragraph3')}</li>
                        <li>{t('politics.policy2.paragraph4')}</li>
                        <li>{t('politics.policy2.paragraph5')}</li>
                        <li>{t('politics.policy2.paragraph6')}</li>
                        <li>{t('politics.policy2.paragraph7')}</li>
                        <li>{t('politics.policy2.paragraph8')}</li>
                        <li>{t('politics.policy2.paragraph9')}</li>
                        <li>{t('politics.policy2.paragraph10')}</li>
                        <li>{t('politics.policy2.paragraph11')}</li>
                        <li>{t('politics.policy2.paragraph12')}</li>
                        <li>{t('politics.policy2.paragraph13')}</li>
                    </ul> */}
                </div>
            ),
        },
    ];
    return (
        <>
            <Animated animation={'animate__fadeIn'}>
                <main className="nv__section nv__politics  text-white-color" id="politics">
                    <section className="nv__section__content">
                        <section className="nv__section__container">
                            <Animated animation={'animate__fadeIn'}>
                                <h1 className="nv__section__title nv__section__title--has-description text-center">
                                    <h4>{t('politics.title')}</h4>
                                </h1>
                            </Animated>

                            <div className="nv__politics__picture">
                                <img src={politicsImage} alt="culure" />
                            </div>

                            <Collapse defaultActiveKey={[]} ghost items={items} />
                        </section>
                    </section>
                </main>
            </Animated>
        </>
    );
}
