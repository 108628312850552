import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Animated from 'components/generals/AnimatedComponent';

export default function ServicesSection() {
    const { t } = useTranslation();
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <>
            <main className="nv__services nv__services--mt nv__section " id="services">
                <section className="nv__section__container">
                    <Animated animation={'animate__fadeInUp'}>
                        <h1 className="nv__section__title nv__section__title--has-description text-center">
                            {t('services.sectionTitle')}
                        </h1>
                    </Animated>

                    <Animated animation={'animate__fadeInUp'}>
                        <p className="nv__section__description text-center">
                            {t('services.description')}
                        </p>
                    </Animated>

                    <section className="nv__services__two__block">
                        <div className="nv__services__half  nv__services__block__item--1">
                            <div className="nv__services__block__item">
                                <div className="nv__services__info">
                                    <p>{t('services.item1.paragraph')}</p>
                                </div>
                            </div>
                        </div>

                        <div className="nv__services__half">
                            <div className="nv__services__block__item nv__services__block__item--2">
                                <div className="nv__services__info">
                                    <p>{t('services.item2.paragraph')}</p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="nv__services__two__block">
                        <div className="nv__services__half">
                            <div className="nv__services__block__item nv__services__block__item--3">
                                <div className="nv__services__info">
                                    <p>{t('services.item3.paragraph')}</p>
                                </div>
                            </div>
                        </div>

                        <div className="nv__services__half">
                            <div className="nv__services__block__item nv__services__block__item--4">
                                <div className="nv__services__info">
                                    <p>{t('services.item4.paragraph')}</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
            </main>
            {/* <CertificationsPage /> */}
        </>
    );
}
