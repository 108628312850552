import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import compromiseImg from 'assets/images/home/compromise/about.png';
import Animated from 'components/generals/AnimatedComponent';
import MisionVision from 'pages/home/mission-vision';
import { useLocation } from 'react-router-dom';

export default function About() {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return (
        <>
            <MisionVision />
            <main
                className="nv__section nv__section--no-margin nv__section--padding nv__imageInfo bg-strong-blue-color text-white-color"
                id="about"
            >
                <section className="nv__section__container">
                    <div className="nv__imageInfo__block nv__imageInfo__block--text">
                        <div className="nv__imageInfo__info">
                            <Animated animation={'animate__fadeInUp'}>
                                <h4>{t('us.title')}</h4>
                                <p>{t('us.paragraph_1')}</p>
                            </Animated>
                        </div>
                    </div>

                    <div className="nv__imageInfo__picture">
                        <Animated animation={'animate__fadeInUp wrapper wrapper--full'}>
                            <div
                                className="nv__imageInfo__picture__image"
                                style={{ backgroundImage: `url(${compromiseImg})` }}
                            ></div>
                        </Animated>
                    </div>
                </section>
            </main>
        </>
    );
}
